import React from 'react'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'
import RichTextRenderer from '../components/richTextRenderer'
import * as articlePageStyling from './article.module.scss'

export default function Page({ data, pageContext, location }) {
    const { contentfulPage } = data

    const pageURL = `${data.site.siteMetadata.siteUrl}${location.pathname}`

    const hasImage = contentfulPage.image

    return (
        <>
            {hasImage && (
                <GatsbySeo
                    openGraph={{
                        images: [
                            {
                                url: contentfulPage.ogImage.gatsbyImageData
                                    .images.fallback.src,
                                width: 1200,
                                height: 630,
                                alt: 'Vinologik',
                            },
                        ],
                    }}
                />
            )}
            <GatsbySeo
                title={contentfulPage.title}
                description={contentfulPage.subtitle}
                openGraph={{
                    title: contentfulPage.title,
                    description: contentfulPage.subtitle,
                    url: pageURL,
                    type: 'article',
                    article: {
                        publishedTime: contentfulPage.createdAt,
                        modifiedTime: contentfulPage.updatedAt,
                        section: contentfulPage.category
                            ? contentfulPage.category.title
                            : '',
                        tags: contentfulPage.tags,
                    },
                }}
            />
            <Hero
                desktopImage={contentfulPage.image}
                mobileImage={contentfulPage.mobileImage}
                heading={contentfulPage.title}
                body={contentfulPage.subtitle}
            />
            <article className={articlePageStyling.article}>
                <RichTextRenderer
                    richText={contentfulPage.content}
                    showFullWineDesign={true}
                    showTableOfContents={false}
                />
            </article>
        </>
    )
}

export const pageQuery = graphql`
    query ($slug: String!) {
        contentfulPage(slug: { eq: $slug }) {
            id
            contentful_id
            title
            subtitle
            createdAt
            updatedAt
            content {
                raw
                references {
                    # ... on ContentfulClass {
                    #     __typename
                    #     contentful_id
                    #     slug
                    # }
                    # ... on contentfulPage {
                    #     __typename
                    #     contentful_id
                    #     slug
                    # }
                    # ... on ContentfulFactBox {
                    #     __typename
                    #     contentful_id
                    # }
                    # ... on ContentfulAsset {
                    #     __typename
                    #     contentful_id
                    #     title
                    #     isPortrait
                    #     gatsbyImageData(
                    #         width: 1280
                    #         layout: CONSTRAINED
                    #         placeholder: BLURRED
                    #     )
                    # }
                    # ... on ContentfulWine {
                    #     ...WineTeaserFields
                    # }
                    ... on ContentfulWineCarousel {
                        __typename
                        contentful_id
                    }
                }
            }
            # image {
            #     title
            #     gatsbyImageData(
            #         width: 1920
            #         layout: FULL_WIDTH
            #         placeholder: BLURRED
            #     )
            # }
            # ogImage: image {
            #     gatsbyImageData(
            #         width: 1200
            #         height: 630
            #         formats: JPG
            #         cropFocus: CENTER
            #         resizingBehavior: FILL
            #     )
            # }
            # mobileImage {
            #     gatsbyImageData(
            #         width: 800
            #         layout: FULL_WIDTH
            #         placeholder: BLURRED
            #     )
            # }
        }
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`
